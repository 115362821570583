<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>I have found an issue or improvement for this website - how do I submit this?</h2>
      <p>
        The source code to this website is open and available to the public. It is maintained on
        <a href="https://gitlab.com/">https://gitlab.com</a>.
      </p>
      <p>
        You are welcome to make a suggestion or correction by submitting an issue to the website's issue tracking.
      </p>
      <div class="list-container">
        <ol class="steps-list">
          <li>Create an account on <a href="https://gitlab.com/">https://gitlab.com</a></li>
          <li>Navigate to the website project maintenance at <a href="https://gitlab.com/flightscope/flightscopeaviation.com.au/">https://gitlab.com/flightscope/flightscopeaviation.com.au/</a></li>
          <li>Select <quote>"Issues"</quote> on the menu, then "New Issue"</li>
          <li>Fill out the details of your suggestion/fix and select "Create issue"</li>
          <li>Flightscope Aviation staff will be notified of your new issue and all communication will occur on that ticket</li>
        </ol>
      </div>
      <p>
        Happy Flying!
      </p>
    </div>
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';

.list-container {
  display: inline-block;
}

.steps-list {
  list-style-type: square;
  text-align: left;
}
</style>
